@import "common";
@import "partials/header";
@import "partials/footer";
@import "partials/loader";
@import "~jquery-nice-select/scss/nice-select";


* {
  box-sizing: border-box;
}

html {
  font-family: $font-family-barlow;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}
body {
  padding: 0;
  margin: 0;

  overscroll-behavior-y: none;
  overflow-x: hidden;
}
body.has-overlay{
  position: fixed;
  overflow-y: auto;
  width: 100%;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: $gray-1;
  opacity: 0.3;
}

body::-webkit-scrollbar-thumb {
  background-color: $blue-7;
  border-radius: 10px;
}


main.has-overlay::after {
  content: "";
  display: block;
  position: fixed;
  padding: 100px 0;
  width: 100%;
  height: 100%;
  top: 20%;
  left: 0;
  background-color: rgba(black, 0.5);
  overflow-y: scroll;
  @include mobile-small() {
    top: 14%;
  }
}

main.content {
  padding-top: 70px;
}

a:visited {
  text-decoration: none;
  color:$gray-old-1;
}

a:active {
  color: red;
}

a {
  color: inherit;
  text-decoration: none;
}

input {
  transition-duration: 1s;
  &:focus {
    border: 1px solid $blue-old-1 !important;
    background-color: $white !important;
    color: $black !important;
    transition: 0.5s;
  }
  &.error {
    border: 1px solid #FF2525 !important;
    background-color: $white !important;
  }
}


.content {
  min-height: calc(100vh - 250px);
}

.btn-primary, .btn-secondary {
  transition-duration: 0.5s;
  @include flex-center-center;
  &:not(.disabled):hover {
    background-color: $blue-old-1 !important;
    box-shadow: 0px 22px 50px rgba(37, 175, 255, 0.3);
    color: $white !important;
    transition: 0.5s;
  }
  &:not(.disabled):focus {
    background-color: $blue-old-6 !important;
    box-shadow: none;
    color: $white !important;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.btn-secondary {
  &:hover {
    border: 1px solid transparent;
  }
}

.btn {
  @include font-barlow(14px, 16px, $font-semi-bold);
  padding: 16px 32px;
  border-radius: 12px;

  &.btn-primary {
    border: 1px solid #25AFFF;
    background-color: #25AFFF;
    color: $gray-1;
  }

  &.btn-secondary {
    border: 1px solid $gray-1;
    color: $gray-1;
  }
}

@include mobile {
  .btn {
    padding: 16px 24px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 88.5%
}

.hidden {
  display: none;
}


@import "../common";

.header-wrapper {
  @include flex(center,center);

  width: 100%;

  position: fixed;
  top: 0;

  background: $gray-1;
  border: 1px solid #F2F2F2;

  transition: transform 400ms ease-in-out 50ms;
  z-index: 9999;
}
.header {
  @include flex(center);
  width: 100%;
  padding: 24px 80px;

  max-width: 1440px;

  @include more-than-desktop {
    padding: 24px 0;
  }

  @media screen and (max-width: 1010px) {
    justify-content: space-between;
  }
  @include mobile() {
    padding: 24px 12px;
  }
  @media screen and (max-width: 430px) {
    padding: 16px 12px;
  }
  @include mobile-small() {
    border-bottom: 1px solid $gray-old-3;
  }
}


.mobile-hamburger, .mobile-hamburger-close {
  @include flex-center-center();
  background-color: $white;
  height: 48px;
  min-width: 48px;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 20px;
  color: #151448;
  display: none;
  margin-right: 36px;
  cursor: pointer;
  &:hover {
    background-color: $blue-old-1 !important;
    box-shadow: 0px 22px 50px rgba(37, 175, 255, 0.3);
    color: $white !important;
    transition: 0.5s;
  }
  @media screen and (max-width: 1010px) {
    margin-right: 0;
    display: flex;
  }
  @include mobile() {
    height: 40px;
    min-width: 40px;
  }
}

.mobile-hamburger-close {
  @media screen and (max-width: 1010px) {
    display: none;
    margin-right: 0;
  }
}

.logo {
  width: 157px;
  img {
    height: 35px;
    width: auto;
    @media screen and (max-width: 430px) {
      height: 30px;
    }
  }
}

.footer-navbar {
  .navbar-list-wrapper {
    list-style: none;
    display: flex;
    font-family: $font-family-barlow;
    font-weight: $font-medium;
    padding: 0;
    margin-left: 80px;
    @include tablet-portrait {
      margin-left: 40px !important;
    }
  }
  .navbar-list-item {
    @include font-barlow(16px, 19px, $font-medium);
    color: $gray-old-1;
    margin-right: 15px !important;
    cursor: pointer;
    &.active {
      a {
        color: $blue-old-7;
      }
    }
    a {
      transition-duration: 1s;
      &:hover {
        color: $blue-old-7;
        transition: 0.3s;
      }
    }
    &:last-child {
      width: 100px;
    }
  }
}

.navbar {
  &-list-wrapper {
    list-style: none;
    display: flex;
    font-family: $font-family-barlow;
    font-weight: $font-medium;
    padding: 0;
    margin-left: 80px;
  }
  &-list-item {
    @include font-barlow(16px, 19px, $font-medium);
    color: $gray-old-1;
    margin-right: 30px;
    cursor: pointer;
    &.active {
      a {
        color: $blue-old-7;
      }
    }
    a {
      transition-duration: 1s;
      &:hover {
        color: $blue-old-7;
        transition: 0.3s;
      }
    }
    &:last-child {
      width: 100px;
    }
  }
  @media screen and (max-width: 1010px) {
    display: none;
  }
}

.buttons-container {
  @include flex-center-end();
  width: 100%;
  @media screen and (max-width: 1010px) {
    width: auto;
  }
  .header-button {
    font-family: $font-family-barlow;
    font-weight: $font-semi-bold;
    height: 48px;
    border-radius: 12px;
    @include flex-center-center();
  }
  .sign-in {
    @media screen and (max-width: 1095px) {
      display: none;
    }
    width: 105px;
    background-color: $white;
    @include border();
    cursor: pointer;
  }
  .sign-up {
    width: 160px;
    background-color: $blue-old-1;
    border: none;
    color: $white;
    margin-left: 10px;
    @include mobile() {
      width: 120px;
      height: 48px;
    }
    @media screen and (max-width: 500px) {
      width: 100px;
      height: 40px;
    }
    a {
      &:visited {
        color: $white;
      }
    }
  }
}

#nav-links-mobile-container {
  position: fixed;
  width: 100%;
  top: 97px;
  z-index: 99999999999;
  background: $white;
  display: none;
  flex-direction: column;

  box-shadow: 0 4px 7px 0 rgba(0,0,0,0.15);

  @include mobile {
    top: 89px;
  }
  @media screen and (max-width: 480px) {
    top: 73px;
  }

  .nav-links-wrapper {
    border: 1px solid $gray-old-3;
  }

  .navbar-mobile-list-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
  }

  .navbar-mobile-list-item {
    padding: 0;
    @include font-barlow(16px, 20px, $font-medium);
    border-bottom: 1px solid rgba(189, 195, 199, 0.5);

    &:last-child {
      border-bottom: none;
    }

    a {
      display: block;

      color: rgba(108, 122, 137, 1);

      padding: 12px 20px;

      &:hover {
        transition: 0.5s;
        color: $blue-old-7;
      }
    }

    &#login {
      @media screen and (max-width: 1095px) {
        display: block;
      }
    }

    &#sign-up {
      display: none;
    }
  }

  .nav-button-wrapper{
    padding: 20px 20px;
    @include flex-center-center();

    .navbar-contact-us-link {
      @include flex-center-center;
      @include font-lato(14px, 48px, $font-bold);
      width: 100%;
      height: 40px;
      background-color: $blue-old-1;
      border: none;
      border-radius: 12px;
      color: $white;
      cursor: pointer;
    }
  }
}

@import "../common";

.footer {
  background: linear-gradient(90.05deg, #2D57E4 -2.81%, #4085DE 29.94%, #398BD5 62.34%, #3B8CE4 80.92%, #4C90EE 92%), #C4C4C4;;
  display: flex;
  flex-direction: column;
}

.footer-top {
  height: calc(#{$default-footer-height} - #{$default-footer-bottom-height});
  padding: 0 80px;

  @include more-than-desktop {
    padding: 0;
  }

  @include mobile {
    padding: 0 20px;

    height: auto;
  }
}
.footer-top-container {
  @include flex(center, space-between);

  padding: 48px 0 10px;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @include tablet-portrait {
    @include flex(center, flex-start, column);
    padding: 48px 0 40px;
  }

  @include mobile-small {
    padding: 48px 0 24px;
  }
}

//.footer-top-container-left {
//  flex: 2;
//}
//.footer-top-container-right {
//  flex: 1;
//}

.footer-bottom {
  padding: 0 80px;

  background: rgba(0, 0, 0, 0.09);

  @include more-than-desktop {
    padding: 0;
  }

  @include mobile {
    padding: 0 20px;
  }
}
.footer-bottom-container {
  @include flex(center, space-between);

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;


  height: calc(#{$default-footer-bottom-height} - 15px);

  color: $gray-1;
}

.logo-navbar-wrapper {
  @include flex-center();
  margin-bottom: 20px;
  @include tablet-landscape() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .logo {
    @include tablet-portrait() {
      @include flex-center-center();
      width: 100%;
    }
  }
  .navbar-list-wrapper {
    padding: 0;

    @include tablet-landscape {
      margin-left: 0;
    }

    @include mobile {
      @include flex(center, space-between);
      flex-wrap: wrap;

      margin: 12px 0 0 !important;

      gap: 10%;
    }

    .navbar-list-item {
      @include font-barlow(16px, 19px, $font-medium);
      color: rgba(255, 255, 255, 0.7);

      a {
        color: rgba(255, 255, 255, 0.7);
        &:hover {
          color: $white;
          transition: 0.3s;
        }
      }

      &.active {
        a {
          color: $white;
        }
      }

      @include mobile {
        margin: 0 0 12px !important;

        width: 35%;

        &:nth-child(2n) {
          text-align: right;
        }
      }
    }
  }
}

//.copyright {
//  @include font-lato(10px, 12px, $font-semi-bold);
//}
//
//.security {
//  font: $font-semi-bold 10px/12px $font-family-lato;
//  &-wrapper {
//    list-style: none;
//    display: flex;
//    margin: 0;
//    .security-list-item {
//      color: $white !important;
//      cursor: pointer;
//      margin-left: 10px;
//      opacity: 0.7;
//      transition-duration: 0.5s;
//      &:hover {
//        opacity: 1;
//        transition: 0.3s;
//      }
//    }
//  }
//}

.security-list-item {
  margin-left: 8px;

  &:visited {
    color: $gray-1;
  }
}

.copyright, .security {
  @include font-lato(11px, 20px, $font-semi-bold)
}

.social-media {
  display: flex;
  justify-content: start;
  &-button {
    @include flex-center-center();
    display: flex !important;
    background: rgba(255, 255, 255, 0.21);
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 16px;
    margin-right: 16px;
    text-decoration: none;
    transition-duration: 1s;
    &:last-child {
      margin-right: 0 !important;
    }
    &:before {
      color: $white;
    }
    &:hover {
      transition: 1s;
      background-color: $white;
      &:before {
        color: $blue-old-7;
      }
    }
  }
  @include tablet-portrait() {
    @include flex-center-center();
  }
}

.keep-in-touch {
  @include tablet-portrait() {
    display: none;
  }
  .form-wrapper {

  }
  &-title {
    color: $white;
    font: $font-semi-bold 20px/24px $font-family-lato;
    @include tablet-portrait() {
      @include flex-center-center();
    }
  }
  &-form-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
    .keep-in-touch-form {
      &-input {
        border: none;
        height: 40px;
        width: 270px;
        background: rgba(255, 255, 255, 0.21);
        border-radius: 12px;
        padding-left: 15px;
        //color: $white !important;
        @media screen and (max-width: 1390px) {
          width: 200px;
        }
        @media screen and (max-width: 1175px) {
          width: 150px;
        }
        &::placeholder {
          color: $white;
        }
        &:active {
          outline: none;
          border: none !important;
          background: rgba(255, 255, 255, 0.21) !important;
          color: $white !important;
          transition: 0.5s;
        }
        &:focus {
          outline: none;
          border: none !important;
          background: rgba(255, 255, 255, 0.21) !important;
          color: $white !important;
          transition: 0.5s;
        }
        &.error {
          color: black !important;
        }
      }
      &-button {
        height: 40px;
        width: 120px;
        background: $white;
        border: none;
        border-radius: 12px;
        margin-left: -20px;
        color: $blue-old-2;
        cursor: pointer;
        transition-duration: 0.5s;
        &:hover {
          box-shadow: 0px 21px 54px #0172B4;
          transition: 0.5s;
        }
        &:focus {
          background-color: $blue-old-6 !important;
          box-shadow: none;
          color: $white !important;
        }
        &.error {
          background: $white !important;
          color: red !important;
        }
        &.success {
          background: $white !important;
          color: $blue-old-2 !important;
        }
      }
    }
  }
  .keep-in-touch-form-body-error-text {
    color: $white;
    opacity: 0.8;
  }
  .keep-in-touch-form-body-success-text {
    color: $white;
    opacity: 0;
    &.visible {
      animation: success-text-fade-in 5000ms ease-in-out;
    }
    @keyframes success-text-fade-in {
      from, 0% { // 0-600ms
        opacity: 0;
        transform: translateY(50px);
      }
      25%, 50% { // 1000ms-2000ms
        opacity: 1;
        transform: translateY(0px);
      }
      80%, to { // 2400ms-end
        opacity: 0;
        transform: translateY(-50px);
      }
    }
  }
}
